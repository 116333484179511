export const TokenStorage = {
  setItem: async (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem: async (key: string) => {
    const k = localStorage.getItem(key)
    if (!k) return k
    try {
      return JSON.parse(k)
    } catch (err) {
      return k
    }
  },
  removeItem: async (key: string) => {
    localStorage.removeItem(key)
  },
}
