const STRIPE_KEYS = {
  LIVE: 'pk_live_3DaeYayFezHhyNjDyzM4Ayhe',
  TEST: 'pk_test_OrZjggCA88Y1wOldz9nJxvMr',
}

const ENV = process.env.GATSBY_CONFIG || 'production'

const baseConfig = {
  apollo: {
    connectToDevTools: ENV !== 'production',
  },
  graphqlSchemaVersion: 5, // Bumb when schema changes
  oauthEndpoint: 'https://oauth.nightzookeeper.com',
  oauthClientId: '6495b15a2cb8503abe6113b07579e3df',
}

const configs = {
  localDebug: {
    env: 'development',
    url: 'http://localhost:8000',
    eduServer: 'http://192.168.1.94:8080',
    edu: 'http://192.168.1.94:8000/edu/zoo/status',
    graphqlEndpoint: 'http://localhost:8080/graphql',
    stripeKey: STRIPE_KEYS.TEST,
    ...baseConfig,
  },
  local: {
    env: 'development',
    url: 'http://localhost:8000',
    edu: 'http://localhost:3000/edu/zoo/login',
    eduRoot: 'http://localhost:3000',
    // eduServer: 'http://localhost:8080',
    // graphqlEndpoint: 'http://localhost:8080/graphql',
    eduServer: 'https://gql-staging.nightzookeeper.com',
    graphqlEndpoint: 'https://gql-staging.nightzookeeper.com/graphql',
    // stripeKey: STRIPE_KEYS.LIVE,
    stripeKey: STRIPE_KEYS.TEST,
    ...baseConfig,
    // oauthEndpoint: 'http://localhost:5000',
    // oauthClientId: '2c2501321d0f8439ac82c44e4a767927',
  },
  staging: {
    env: 'staging',
    url: 'https://nzk-parent-dashboard--pr10-feature-account-owne-bdswrvvf.web.app',
    eduServer: 'https://gql-staging.nightzookeeper.com',
    edu: 'https://staging.nightzookeeper.com/edu/zoo/login',
    eduRoot: 'https://staging.nightzookeeper.com',
    graphqlEndpoint: 'https://gql-staging.nightzookeeper.com/graphql',
    stripeKey: STRIPE_KEYS.LIVE,
    ...baseConfig,
  },
  production: {
    env: 'production',
    url: 'https://dashboard.nightzookeeper.com',
    eduServer: 'https://gql.nightzookeeper.com',
    edu: 'https://www.nightzookeeper.com/edu/zoo/status',
    eduRoot: 'https://www.nightzookeeper.com',
    graphqlEndpoint: 'https://gql.nightzookeeper.com/graphql',
    stripeKey: STRIPE_KEYS.LIVE,
    ...baseConfig,
  },
}

export default configs[ENV]
