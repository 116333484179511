import {
  ApolloClient,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'
import localForage from 'localforage'
import fetch from 'unfetch'
import config from '../config'
import { TokenStorage } from '../lib/TokenStorage'

const link = new BatchHttpLink({
  uri: config.graphqlEndpoint,
  fetch,
  batchMax: 10, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
})

const authLink = setContext(async (_, { headers }) => {
  const rawToken = await TokenStorage.getItem('token')
  let token = rawToken?.value
  if (!token) {
    token = sessionStorage.getItem('token')
  }

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    StarTutoringSubscription: {
      fields: {
        schedule: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
      },
    },
  },
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'UserCurriculumGroup':
        return null // Prevents caching stats in common group
      default:
        return defaultDataIdFromObject(object)
    }
  },
})

const persistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(localForage),
  debug: config.env === 'development',
  trigger: 'write',
})

if (typeof window !== 'undefined') {
  cache.persistor = persistor
  persistor.restore()
}

export const cachePersistor = persistor

export const client = new ApolloClient({
  link: authLink.concat(link),
  resolvers: {},
  cache,
})
